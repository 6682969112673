import '../App.css';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { Container } from 'react-bootstrap';
import { isMobile } from 'react-device-detect';

import modulos1 from '../img/modulos/modulos1.png'
import modulos2 from '../img/modulos/modulos2.png'
import modulos3 from '../img/modulos/modulos3.png'
import modulos4 from '../img/modulos/modulos4.png'

function Modulos() {
    return (<>
        <div>
        <Container>
        <div style={{ height: isMobile? '15px':'30px' }}></div>
        <div className='home-title-black'>MÓDULOS ITINERANTES Y PLEGABLES</div>
        <div style={{ height: isMobile? '15px':'30px' }}></div>
        <Container>
                <Row>
                    <Col sm={4} xl={8} xs={12}>
                        <Row>
                            <Col>
                                <Container>
                                    <Row className="align-items-center containers-photos">
                                        <Col className='containers-photos containers-photos-left'>
                                            <img src={modulos1} className='imgs-containers' />
                                        </Col>
                                        <Col className='containers-photos containers-photos-right text-desc-book'>
                                                <div className='text-title-book'>
                                                <div className='text-desc-book'>Fabricados con termoformado y de estructura metálica, los módulos itinerantes y plegables son de fácil transportación y armado, pues caben en una maleta. </div></div>
                                        </Col>
                                    </Row>
                                </Container>
                            </Col>
                        </Row>
                        <Row>
                            <Col className='colums-padding'><img src={modulos2} className='imgs-containers photos-stants-right' /></Col>
                            <Col className='colums-padding'><img src={modulos3 } className='imgs-containers photos-stants' /></Col>
                        </Row>
                    </Col>
                    <Col sm={4} xl={4} xs={12}>
                        <img src={modulos4} className='imgs-containers' />
                    </Col> 
                </Row>
            </Container>
            <Row>
                <Col md={10}xs={9}>
                </Col>
                <Col md={2} xs={3} className='home-title-book'>
                <div><a href="/?s=refBook">Book.</a></div>
                </Col>
            </Row>
            </Container>
        <div style={{ height: isMobile? '15px':'86px' }}></div>
    </div>
    
    </>)
}
export default Modulos;