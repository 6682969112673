import '../App.css';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { Container } from 'react-bootstrap';
import { isMobile } from 'react-device-detect';

import exibidor from '../img/exibidor1.png'
import exibidor2 from '../img/exibidor3.png'
import exibidor3 from '../img/exividor4.png'
import exibidor4 from '../img/exibidor2.png'



function Pure() {
    return (<>
        <div>
        <Container>
        <div style={{ height:  isMobile? '15px':'30px' }}></div>
        <div className='home-title-black'>PURE HARVEST</div>
        <div style={{ height:  isMobile? '15px':'30px' }}></div>
        <Container>
          
        <Row>
                    <Col sm={4} xl={8} xs={12}>
                        <Row className="align-items-center containers-photos">
                            <Col className='containers-photos containers-photos-left'>
                                <img src={exibidor} className='imgs-containers' />
                            </Col>
                            <Col className='containers-photos containers-photos-right text-desc-book'>
                                    <div className='text-title-book'>EXHIBIDORES. <br/>
                                        <div className='text-desc-book'>Es una marca 100% mexicana en la industria de frutas y verduras, dedicada a comercializar productos del campo. Busca ser pionera en la producción durante todo el año de productos frescos asequibles y de primera calidad.</div></div>
                            </Col>
                        </Row>
                        <Row>
                            <Col><img src={exibidor2} className='imgs-containers photos-stants-right' /></Col>
                            <Col><img src={exibidor3} className='imgs-containers photos-stants' /></Col>
                        </Row>
                    </Col>
                    <Col sm={4} xl={4} xs={12}>
                        <img src={exibidor4} className='imgs-containers' />
                    </Col> 
            </Row>  
        
            <Row>
                <Col md={10} xs={9}>
                </Col>
                <Col md={2} xs={3} className='home-title-book'>
                <div><a href="/?s=refBook">Book.</a></div>
                </Col>
            </Row>

        </Container>
        
                
  


                
        </Container>
        <div style={{ height:  isMobile? '15px':'86px' }}></div>
    </div>
    
    </>)
}
export default Pure;