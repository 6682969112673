import '../App.css';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { Container } from 'react-bootstrap';
import { isMobile } from 'react-device-detect';

import halo1 from '../img/walmart/walmart1.png'
import halo2 from '../img/walmart/walmart2.png'
import halo3 from '../img/walmart/walmart3.png'
import halo4 from '../img/walmart/walmart4.png'
import halo5 from '../img/walmart/walmart5.png'
import halo6 from '../img/walmart/walmart6.png'
import halo7 from '../img/walmart/walmart7.png'


function Walmart() {
    return (<>
        <div>
        <Container>
        <div style={{ height: isMobile? '15px': '30px' }}></div>
        <div className='home-title-black'>Walmart</div>
                <div style={{ height: isMobile? '15px': '30px' }}></div>
                </Container>
        <Container>                        
            </Container>
            <Container>
                <Row>
                        <Col sm={4} xl={8} xs={12}>
                            <Row className="align-items-center containers-photos">
                                <Col className='containers-photos containers-photos-left'>
                                    <img src={halo3} className='imgs-containers' />
                                </Col>
                                <Col className='containers-photos containers-photos-right text-desc-book'>
                                        <div className='text-title-book'>EXHIBIDORES. <br/>
                                            <div className='text-desc-book'>Desarrollamos islas para ser instaladas en Walmart Supercenter, con la finalidad de que las marcas realicen activaciones y se optimice la exhibición mediante una estructura llamativa que resalte con los propios productos.</div></div>
                                </Col>
                            </Row>
                            <Row>
                                <Col className='colums-padding'><img src={halo6} className='imgs-containers photos-stants-right' /> <img src={halo4} className='imgs-containers photos-stants-right' /></Col>
                                <Col className='colums-padding'><img src={halo1} className='imgs-containers photos-stants-right' /> <img src={halo7} className='imgs-containers photos-stants-right' /></Col>
                            </Row>
                        </Col>
                        <Col sm={4} xl={4} xs={12}>
                            <img src={halo2} className='imgs-containers margin-zero' />
                            <img src={halo5} className='imgs-containers margin-zero' />
                    </Col> 
                </Row>
                <Row>
                    <Col md={10}xs={9}>
                        <br/>
                    </Col>
                    <Col md={2} xs={3} className='home-title-book'>
                    <div><a href="/?s=refBook">Book.</a></div>
                    </Col>
                </Row>
            </Container>
        <div style={{ height:  isMobile? '15px':'86px' }}></div>
    </div>
    
    </>)
}
export default Walmart;