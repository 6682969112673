import '../App.css';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { Container } from 'react-bootstrap';
import { isMobile } from 'react-device-detect';

import vol1 from '../img/volumenes/vol1.png'
import vol2 from '../img/volumenes/vol2.png'
import vol3 from '../img/volumenes/vol3.png'
import vol4 from '../img/volumenes/vol4.png'
import vol5 from '../img/volumenes/vol5.png'
import vol6 from '../img/volumenes/vol6.png'
import vol7 from '../img/volumenes/vol7.png'

function Volumenes() {
    return (<>
        <div>
        <Container>
        <div style={{ height: isMobile? '15px':'30px' }}></div>
        <div className='home-title-black'>VOLUMÉTRICOS</div>
        <div style={{ height: isMobile? '15px':'30px' }}></div>
        <Container>
                <Row>
                    <Col sm={4} xl={8} xs={12}>
                        <Row>
                            <Col>
                                <Container>
                                    <Row className="align-items-center containers-photos">
                                        <Col className='containers-photos containers-photos-left'>
                                            <img src={vol1} className='imgs-containers' />
                                        </Col>
                                        <Col className='containers-photos containers-photos-right text-desc-book'>
                                                <div className='text-title-book'>VOLUMÉTRICOS.<br/>
                                                <div className='text-desc-book'>Diseñamos, producimos e instalamos todo tipo de volumétricos.</div></div>
                                        </Col>
                                    </Row>
                                </Container>
                            </Col>
                        </Row>
                        <Row>
                            <Col className='colums-padding'>
                                <img src={vol2} className='imgs-containers photos-stants-right' />
                                <br/>
                                <img src={vol3} className='imgs-containers photos-stants-right' />
                            </Col>
                            <Col className='colums-padding'>
                                <img src={vol4 } className='imgs-containers photos-stants' />
                                <br/>
                                <img src={vol5 } className='imgs-containers photos-stants' />
                            </Col>
                        </Row>
                    </Col>
                    <Col sm={4} xl={4} xs={12}>
                        <img src={vol6} className='imgs-containers' /><br/>
                        <br/>
                        <img src={vol7} className='imgs-containers' />
                    </Col> 
                </Row>
            </Container>
            <Row>
                <Col md={10}xs={9}>
                </Col>
                <Col md={2} xs={3} className='home-title-book'>
                <div><a href="/?s=refBook">Book.</a></div>
                </Col>
            </Row>
            </Container>
        <div style={{ height: isMobile? '15px':'86px' }}></div>
    </div>
    
    </>)
}
export default Volumenes;