import '../App.css';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { Container } from 'react-bootstrap';
import { isMobile } from 'react-device-detect';

import xp1 from '../img/xp1.png'
import xp2 from '../img/xp2.png'
import xp3 from '../img/xp3.png'
import xp4 from '../img/xp4.png'


function Xp() {
    return (<>
        <div>
        <Container>
        <div style={{ height: '30px' }}></div>
        <div className='home-title-black'>XP DE LIVERPOOL</div>
                <div style={{ height:  isMobile? '15px'  :'30px' }}></div>
                </Container>
                <Container>
                <Row>
                        <Col sm={4} xl={8} xs={12}>
                            <Row className="align-items-center containers-photos">
                                <Col className='containers-photos containers-photos-left'>
                                    <img src={xp1} className='imgs-containers' />
                                </Col>
                                <Col className='containers-photos containers-photos-right text-desc-book'>
                                        <div className='text-title-book'>PUBLICIDAD EXTERIOR. <br/>
                                            <div className='text-desc-book'>Es un espacio para mantenerte en
                                            contacto con todo lo que te interesa en
                                            videojuegos y cultura pop. Puedes
                                            encontrar juegos arcade, coleccionables y
                                            estaciones de juegos con lo último en
                                            consolas y PC Gaming. </div></div>
                                </Col>
                            </Row>
                            <Row>
                            <Col>
                                <img src={xp2} className='imgs-containers photos-stants-right' />
                            </Col>
                               
                            </Row>
                        </Col>
                        <Col sm={4} xl={4} xs={12}>
                            <img src={xp3} className='imgs-containers margin-zero' />
                        </Col> 
                    <Row>
                    <img src={xp4} className='imgs-containers img-separation-top' />
                    </Row>
                </Row>
                <Row>
                <Col md={10} xs={9}>
                </Col>
                <Col md={2} xs={3} className='home-title-book'>
                <div><a href="/?s=refBook">Book.</a></div>
                </Col>
            </Row>
            </Container>
        <div style={{ height: isMobile? '15px'  :'86px' }}></div>
    </div>
    
    </>)
}
export default Xp;