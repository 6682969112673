import '../App.css';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { Container } from 'react-bootstrap';
import { isMobile } from 'react-device-detect';

import hp1 from '../img/hp1.png'
import hp2 from '../img/hp2.png'
import hp3 from '../img/hp3.png'
import hp4 from '../img/hp4.png'
import hp5 from '../img/hp5.png'
import hp6 from '../img/hp6.png'
import hp7 from '../img/hp7.png'
import hp8 from '../img/hp8.png'

function Hp() {
    return (<>
        <div>
        <Container>
        <div style={{ height: isMobile? '15px'  :'30px' }}></div>
        <div className='home-title-black'>HP</div>
        <div style={{ height: isMobile? '15px'  :'30px' }}></div>
                <Container>
                <Row>
                        <Col sm={4} xl={8} xs={12}>
                            <Row className="align-items-center containers-photos">
                                <Col className='containers-photos containers-photos-left'>
                                    <img src={hp1} className='imgs-containers' />
                                </Col>
                                <Col className='containers-photos containers-photos-right text-desc-book'>
                                        <div className='text-title-book'><span style={{ textTransform: 'uppercase' }}>Exhibidores, stands, marketing experience e interiorismo y decoración. </span><br/>
                                            <div className='text-desc-book'>Es una de las mayores e importantes empresas de tecnologías de la información. Fabrica y comercializa hardware y software. Es líder en venta de computadoras personales e impresoras en el mundo.</div></div>
                                </Col>
                            </Row>
                            <Row>
                                
                                <Col>
                                    <Row><img src={hp2} className='imgs-containers photos-stants-right' />
                                    </Row>
                                <Row> <img src={hp6} className='imgs-containers' /></Row>
                                </Col>
                                <Col>
                                    <Row><img src={hp3} className='imgs-containers photos-stants' /></Row>
                                   <Row> <img src={hp7} className='imgs-containers' /></Row>
                                </Col>
                                
                            </Row>
                    </Col>
                    
                        <Col sm={4} xl={4} xs={12}>
                            <Row>    <img src={hp4} className='imgs-containers' />
                            
                            </Row>
                        <Row>
                                <img src={hp5} className='imgs-containers img-separation-top' />
                                <img src={hp8} className='imgs-containers img-separation-top' />
                        </Row>
                    </Col> 
                    <Row>
                        <Col sm={1} xl={4} xs={4}>
                       
                        </Col>
                        <Col sm={1} xl={4} xs={4}>
                        
                        </Col>
                        <Col sm={2} xl={4} xs={4}>
                        
                        </Col>   
                    </Row>
                    
  </Row>


                    <Row>
                        <Col md={10} xs={9}>
                        </Col>
                        <Col md={2} xs={3} className='home-title-book'>
                        <div><a href="/?s=refBook">Book.</a></div>
                        </Col>
                    </Row>
       </Container>            
        </Container>
        <div style={{ height:  isMobile? '15px'  : '86px' }}></div>
    </div>
    
    </>)
}
export default Hp;