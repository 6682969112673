import '../App.css';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { Container } from 'react-bootstrap';
import { isMobile } from 'react-device-detect';

import telcel1 from '../img/telcel/telcel1.png'
import telcel2 from '../img/telcel/telcel2.png'
import telcel3 from '../img/telcel/telcel3.png'

function Telcel() {
    return (<>
        <div>
        <Container>
        <div style={{ height: isMobile? '15px':'30px' }}></div>
        <div className='home-title-black'>TELCEL</div>
        <div style={{ height: isMobile? '15px':'30px' }}></div>
        <Container>
                <Row>
                    <Col sm={4} xl={8} xs={12}>
                        <Row>
                            <Col>
                                <Container>
                                    <Row className="align-items-center containers-photos">
                                        <Col className='containers-photos containers-photos-left'>
                                            <img src={telcel1} className='imgs-containers' />
                                        </Col>
                                        <Col className='containers-photos containers-photos-right text-desc-book'>
                                            <div className='text-title-book'>CENTROS DE EXPERIENCIAS.<br/>
                                            <div className='text-desc-book'>Contamos con la capacidad de desarrollar verdaderos centros de experiencia para los consumidores y aumentar así su tiempo de permanencia, clave para generar mayores oportunidades de compra.</div></div>
                                        </Col>
                                    </Row>
                                </Container>
                            </Col>
                        </Row>
                        <Row>
                            <Col className='colums-padding'><img src={telcel2} className='imgs-containers photos-stants-right' /></Col>
                        </Row>
                    </Col>
                    <Col sm={4} xl={4} xs={12}>
                        <img src={telcel3} className='imgs-containers' />
                    </Col> 
                </Row>
            </Container>
            <Row>
                <Col md={10}xs={9}>
                </Col>
                <Col md={2} xs={3} className='home-title-book'>
                <div><a href="/?s=refBook">Book.</a></div>
                </Col>
            </Row>
            </Container>
        <div style={{ height: isMobile? '15px':'86px' }}></div>
    </div>
    
    </>)
}
export default Telcel;