import '../App.css';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { Container } from 'react-bootstrap';
import { isMobile } from 'react-device-detect';

import _3d1 from '../img/3d/3d1.png'
import _3d2 from '../img/3d/3d2.png'
import _3d3 from '../img/3d/3d3.png'
import _3d4 from '../img/3d/3d4.png'
import _3d5 from '../img/3d/3d5.png'
import _3d6 from '../img/3d/3d6.png'

function _3D() {
    return (<>
        <div>
        <Container>
        <div style={{ height: isMobile? '15px':'30px' }}></div>
        <div className='home-title-black'>3D</div>
        <div style={{ height: isMobile? '15px':'30px' }}></div>
        <Container>
                <Row>
                    <Col sm={4} xl={8} xs={12}>
                        <Row>
                            <Col>
                                <Container>
                                    <Row className="align-items-center containers-photos">
                                        <Col className='containers-photos containers-photos-left'>
                                            <img src={_3d1} className='imgs-containers' />
                                        </Col>
                                        <Col className='containers-photos containers-photos-right text-desc-book'>
                                                <div className='text-title-book'>MODELADO 3D <br/>
                                                <div className='text-desc-book'>Nuestros diseñadores 3D manejan los más sofisticados programas como son Rhinoceros, Blender, C Brush y Nomad.</div></div><br/>
                                                <div className='text-title-book'>IMPRESIÓN 3D <br/>
                                                <div className='text-desc-book'>Contamos con impresoras 3D, resina fotosensible, PLA y ABS.</div></div>
                                        </Col>
                                    </Row>
                                </Container>
                            </Col>
                        </Row>
                        <Row>
                            <Col className='colums-padding'>
                                <img src={_3d2} className='imgs-containers photos-stants-right' />
                                <br/>
                                <img src={_3d3} className='imgs-containers photos-stants-right' />
                            </Col>
                            <Col className='colums-padding'><img src={_3d4 } className='imgs-containers photos-stants' /></Col>
                        </Row>
                    </Col>
                    <Col sm={4} xl={4} xs={12}>
                        <img src={_3d5} className='imgs-containers' /><br/>
                        <br/>
                        <img src={_3d6} className='imgs-containers' />
                    </Col> 
                </Row>
            </Container>
            <Row>
                <Col md={10}xs={9}>
                </Col>
                <Col md={2} xs={3} className='home-title-book'>
                <div><a href="/?s=refBook">Book.</a></div>
                </Col>
            </Row>
            </Container>
        <div style={{ height: isMobile? '15px':'86px' }}></div>
    </div>
    
    </>)
}
export default _3D;