import '../App.css';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { Container } from 'react-bootstrap';
import PhotoAlbum from "react-photo-album";
import { isMobile } from 'react-device-detect';

import pepsi1 from '../img/pepsi1.png'
import pepsi2 from '../img/pepsi2.png'
import pepsi3 from '../img/pepsi3.png'
import pepsi4 from '../img/pepsi4.png'

const photos = [
    {
        src: pepsi1,
        width: 800,
        height: 700,
        title: "Six Flags",
        subtitle:"Interiorismo y decoración y publicidad exterior.",
     
    },
    {
        src: pepsi2,
        width: 548,
        height: 680,
        title: "Six Flags",
        subtitle:"Interiorismo y decoración y publicidad exterior.",
     
    },
    {
        src: pepsi4,
        width: 548,
        height: 680,
        title: "Six Flags",
        subtitle:"Interiorismo y decoración y publicidad exterior.",
     
    }

]

function Pepsi() {
    return (<>
        <div>
        <Container>
            <div style={{ height: isMobile? '15px': '30px' }}></div>
            <div className='home-title-black'>PEPSI</div>
            <div style={{ height: isMobile? '15px': '30px' }}></div>
            <Row>
                    <Col sm={4} xl={8} xs={12}>
                        <Row className="align-items-center containers-photos">
                            <Col className='containers-photos containers-photos-left'>
                                <img src={pepsi1} className='imgs-containers' />
                            </Col>
                            <Col className='containers-photos containers-photos-right text-desc-book'>
                                    <div className='text-title-book'>EXHIBIDORES. <br/>
                                        <div className='text-desc-book'>Ya sean de gran tamaño o de sobremesa, nuestros exhibidores cumplen con el objetivo de vestir de manera atractiva el retail e incrementar las ventas del producto en el punto de venta. Tenemos más de 100 promotores a nuestro cargo para instalar exhibidores en 500 puntos de venta al día, con costos muy bajos y disminuyendo considerablemente la huella de carbono.</div></div>
                            </Col>
                        </Row>
                        <Row>
                            <Col className='colums-padding'><img src={pepsi2} className='imgs-containers photos-stants-right' /></Col>
                            <Col className='colums-padding'><img src={pepsi3 } className='imgs-containers photos-stants pepsi-background' /></Col>
                        </Row>
                    </Col>
                    <Col sm={4} xl={4}xs={12}>
                        <img src={pepsi4} className='imgs-containers pepsi-background margin-zero' />
                    </Col> 
            </Row>
       
            <Row>
                <Col md={10} xs={9}>
                </Col>
                <Col md={2} xs={3} className='home-title-book'>
                <div><a href="/?s=refBook">Book.</a></div>
                </Col>
            </Row>
        </Container>
        <div style={{ height: isMobile? '15px': '86px' }}></div>
    </div>
    
    </>)
}
export default Pepsi;