import '../App.css';
import Nav from 'react-bootstrap/Nav';
import { Navbar, Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import PhotoAlbum from "react-photo-album";

function Marca() {
    return (
        <>
            <div>
                <div className='home-title-black'>
                    MARCA
                </div>

                <Container>


                </Container>

            </div>
        </>
    );
}
export default Marca;