import { useState, useEffect, useRef } from 'react'
import {isMobile} from 'react-device-detect';
import ReactPlayer from 'react-player'

import Nav from 'react-bootstrap/Nav';
import { Navbar, Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import PhotoAlbum from "react-photo-album";
import Marquee from "react-fast-marquee";
import Accordion from 'react-bootstrap/Accordion';
import Carousel from 'react-bootstrap/Carousel';

import video from '../img/video.png'

import kuka from '../img/posibilidades/kuka.png'
import escaner from '../img/posibilidades/escaner.png'
import impresora from '../img/posibilidades/impresora.png'
import laser from '../img/posibilidades/laser.png'
import therm from '../img/posibilidades/therm.png'

import mas from '../img/mas.png'
import cashi from '../img/cashi.png'
import bic from '../img/bic.png'
import sams from '../img/sams.png'
import walmart from '../img/walmart.png'
import mercado from '../img/mercadopago.png'
import bandera from '../img/bandera.png'
import nestle from '../img/nestle.png'
import speedo from '../img/speedo.png'
import nature from '../img/nature.png'
import paloma from '../img/paloma.png'
import circulo from '../img/circulo.png'
import servicios from '../img/servicios.png'
import '../App.css';
import 'animate.css';

const HOST = 'https://studio36mx.com/'
//const HOST = 'http://localhost:3000/'

const photos = [
    {
        src: HOST + 'services/Sixflags.png',
        width: 357,
        height: 487,
        title: "Six Flags",
        subtitle:"Interiorismo y decoración y publicidad exterior.",
        path: "/six"
    },
    {
        src: HOST + 'services/xp.png',
        width: 549,
        height: 412,
        title: "XP",
        subtitle:"Publicidad exterior.",
        path: "/xp"
    },
    {
        src: HOST + 'services/hp.png',
        width: 411,
        height: 491,
        title: "hp",
        subtitle:"Exhibidores, stands e interiorismo y decoración.",
        path: "/hp"
    },
    {
        src: HOST + 'services/arte.png',
        width: 411,
        height: 607,
        title: "ARTE EN UNICEL Y/O POLIURETANO",
        subtitle:"",
        path: "/arte"
    },
    {
        src: HOST + 'services/freefire.png',
        width: 409,
        height: 449,
        title: "Free Fire",
        subtitle:"Interiorismo y decoración.",
        path: "/free"
    },
    {
        src: HOST + 'services/tequila.png',
        width: 600,
        height: 423,
        title: "tequila primo.",
        subtitle:"Moldes.",
        path: "/tequila"
    },
    {
        src: HOST + 'services/telcel.png',
        width: 436,
        height: 343,
        title: "TELCEL",
        subtitle:"Centros de experiencia.",
        path: "/telcel"
    },
    {
        src: HOST + 'services/interiorismo.png',
        width: 414,
        height: 268,
        title: "INTERIORISMO",
        subtitle:"",
        path: "/interiorismo"
    },
    {
        src: HOST + 'services/3d.png',
        width: 545,
        height: 443,
        title: "3D",
        subtitle:"",
        path: "/3d"
    },
    {
        src: HOST + 'services/modulos.png',
        width: 1100,
        height: 714,
        title: "MÓDULOS ITINARANTES Y PLEGABLES",
        subtitle:"",
        path: "/modulos"
    },
    {
        src: HOST + 'services/walmart.png',
        width: 360,
        height: 532,
        title: "WALMART",
        subtitle:"Exhibidores.",
        path: "/walmart"
    },
    {
        src: HOST + 'services/pepsi.png',
        width: 993,
        height: 693,
        title: "Pepsi",
        subtitle:"Exhibidores.",
        path: "/pepsi"
    },
    {
        src: HOST + 'services/ramon.png',
        width: 416,
        height: 555,
        title: "DON RAMÓN",
        subtitle:"Glorificadores y exhibidos.",
        path: "/donramon"
    },
    {
        src: HOST + 'services/volumetricos.png',
        width: 416,
        height: 517,
        title: "VOLUMÉTRICOS",
        subtitle:"",
        path: "/volumetricos"
    }
    
];
const goToService = (e) => {
    console.log("e", e.photo.path)
    window.location.href = e.photo.path
}
function Home({ refHome, refUs, refBook, refServices, refBrands }) {
    const [ proyectIndex, setProyectIndex ] = useState(0)
    const [ serviceIndex, setServiceIndex ] = useState(5)
    const handleOnSlide = (e) => {
        setProyectIndex(e);
    }
    const handleOnClick = (e) => {
        console.log(e);
        setServiceIndex(e);
    }
    

    return (
        <>
       
        <div ref={ refHome }>
            <div>
                <Row>
                    <div>
                        <ReactPlayer url='st36.mp4' playing={ true } loop={ true } muted={ true } controls={ true } className="player-style"/>
                    </div>
                </Row>
            </div>

            <div ref={refUs} className='section'>
                <Container>
                    <div className='home-title-black'>Nosotros. <span className="line"></span> </div>
                        <div className='nosotros-desc'>
                            
                            Somos una empresa de diseño industrial integral con la mayor capacidad tecnológica y humana del
                            mercado, conformada por un equipo de profesionales multidisciplinarios, dedicados y creativos.
                            <br/>
                            Diseñamos productos y servicios al ritmo de nuestros clientes, con enfoque a la innovación.
                            Ofrecemos soluciones donde el límite es la imaginación
                            
                    </div>
                </Container>
            </div>

            <div className='section home-pos' style={{ paddingBottom: '0' }}>
                <Container >
                    <Row>
                        <Col md={ 7 } >
                            <br/><br/>
                            <div className='home-title-white'>POSIBILIDADES INFINITAS</div><br/>

                            { proyectIndex == 0 &&
                                <div id="proyect_0" className={ proyectIndex == 0 ? "animate__animated animate__fadeInLeft send-front-90 animate__fast" : "animate__animated animate__slideOutRight send-background" }>
                                    <div className='home-subtitle-white'>Kuka<br/></div> <br/>
                                    <div className='home-desc-section'>Se trata de un brazo robótico de cinco ejes para la elaboración de esculturas y moldes. Trabaja con todo tipo de moldes, incluso mármol.</div>
                                </div>
                            }
                            { proyectIndex == 1 &&
                                <div id="proyect_1" className={ proyectIndex == 1 ? "animate__animated animate__fadeInLeft send-front-90 animate__fast" : "animate__animated animate__slideOutRight send-background" }>
                                    <div className='home-subtitle-white'>Thermwood<br/></div> <br/>
                                    <div className='home-desc-section'>
                                        Es una CNC, diseñada para recortar piezas
                                        formadas, patrones o moldes utilizando
                                        movimientos simultáneos de cinco ejes. Con ella
                                        es posible elaborar moldes e incluso
                                        automóviles.
                                    </div>
                                </div>
                            }
                            { proyectIndex == 2 &&
                                <div id="proyect_2" className={ proyectIndex == 2 ? "animate__animated animate__fadeInLeft send-front-90 animate__fast" : "animate__animated animate__slideOutRight send-background" }>
                                    <div className='home-subtitle-white'>Escaner Leo Artec<br/></div> <br/>
                                    <div className='home-desc-section'>
                                        Artec Leo es el único escáner 3D profesional
                                        inalámbrico y completamente autónomo.
                                        Gracias a su nue
                                        vo procesador NVIDIA Jetson
                                        TX2 incorporado, su pantalla HD de 5” y su
                                        batería, es la solución definitiva de escaneo 3D
                                        para una captura de datos rápida, precisa y de
                                        alta calidad.
                                    </div>
                                </div>
                            }
                            { proyectIndex == 3 &&
                                <div id="proyect_3" className={ proyectIndex == 3 ? "animate__animated animate__fadeInLeft send-front-90 animate__fast" : "animate__animated animate__slideOutRight send-background" }>
                                    <div className='home-subtitle-white'>Láser de fibra óptica MOPA<br/></div> <br/>
                                    <div className='home-desc-section'>
                                        Los lásers de tipo MOPA permiten marcar
                                        plásticos con alto contraste, incluso de colores
                                        oscuros, genera un marcado negro en aluminio y
                                        crea colores en metal. El láser de MOPA es una
                                        solución de marcado más rápida que el láser de
                                        fibra y altamente eficiente con los plásticos y
                                        metales. 
                                    </div>
                                </div>
                            }
                            { proyectIndex == 4 &&
                                <div id="proyect_4" className={ proyectIndex == 4 ? "animate__animated animate__fadeInLeft send-front-90 animate__fast" : "animate__animated animate__slideOutRight send-background" }>
                                    <div className='home-subtitle-white'>Impresora Digital Sharp MX7580<br/></div> <br/>
                                    <div className='home-desc-section'>
                                        Posee una capacidad de papel de 3,100 hojas
                                        estándar y máxima de 8,500.<br/>
                                        Cuenta con una alta velocidad, alta productividad
                                        y una amplia variedad de opciones para
                                        acabados profesionales.
                                    </div>
                                </div>
                            }
                        </Col>

                        <Col md={ 5 } >
                        <Carousel controls={true} indicators={false} interval={ 8000 } onSlide={ handleOnSlide } pause={ false }>
                            <Carousel.Item className="animate__animated animate__fadeInUp send-front-90 animate__fast">
                                <img src={ kuka } className="video-home img-carr"/>
                            </Carousel.Item>
                            <Carousel.Item className="animate__animated animate__fadeInUp send-front-90 animate__fast">
                                <img src={ therm } className="video-home img-carr"/>
                            </Carousel.Item>
                            <Carousel.Item className="animate__animated animate__fadeInUp send-front-90 animate__fast">
                                <img src={ escaner } className="video-home img-carr"/>
                            </Carousel.Item>
                            <Carousel.Item className="animate__animated animate__fadeInUp send-front-90 animate__fast">
                                <img src={ laser } className="video-home img-carr"/>
                            </Carousel.Item>
                            <Carousel.Item className="animate__animated animate__fadeInUp send-front-90 animate__fast">
                                <img src={ impresora } className="video-home img-carr"/>
                            </Carousel.Item>
                        </Carousel>
                        </Col>
                    </Row>
                </Container>
                </div>
                <div>
                    <Container className="full-container">
                        </Container>
              
                </div>
                
                <div ref={refBook} className='section'>
                    <Container>
                        <div className='home-title-black'> Book. <span className="line"></span></div>
                        <br/>
                        <PhotoAlbum layout="columns" photos={photos}  onClick={ goToService } columns={ isMobile ? 1 : 3 } />
                        {/* renderPhoto={({ photo, wrapperStyle, renderDefaultPhoto }) => (
                                <div style={{ position: "relative", ...wrapperStyle }}>
                                    { renderDefaultPhoto({ wrapped: false }) }
                                    <div className='photo-desc'>
                                        <div style={{ textTransform: "uppercase", textAlign: 'right' }} className="title-item">
                                            {photo.title}
                                        </div>  
                                        <div className='subtitle-item'>
                                            {photo.subtitle}
                                        </div>
                                    </div> 
                                </div>
                            )} */}
                    </Container>
                </div>
                
                <div ref={refServices} className='section home-services'>
    {isMobile ? (
        <Container >
            <Row>
                                <div className='home-title-ment'>Servicios. <span className="line-ment" style={{ borderColor: '#71c2b3' }}></span>
                                </div>
            </Row>
            <Row >
                <Accordion className="accordion-container">
                    <Accordion.Item eventKey="7">
                        <Accordion.Header><span className='home-services-title'>Marketing Experience</span></Accordion.Header>
                            <Accordion.Body>
                                <div className='service-tab pb-3'><img src={circulo} className='img-circulo'/>   Cenefas digitales.</div>
                                <div className='service-tab pb-3'><img src={circulo} className='img-circulo'/>   Preciadores digitales.</div>
                                <div className='service-tab pb-3'><img src={circulo} className='img-circulo'/>   Wi-spot.</div>
                                <div className='service-tab pb-3'><img src={circulo} className='img-circulo'/>   Video box.</div>
                                <div className='service-tab pb-3'><img src={circulo} className='img-circulo'/>   Vending machine.</div>
                                <div className='service-tab pb-3'><img src={circulo} className='img-circulo'/>   Retail analytics.</div>
                                <div className='service-tab pb-3'><img src={circulo} className='img-circulo'/>   Self checkout.</div>
                                <div className='service-tab pb-3'><img src={circulo} className='img-circulo'/>   Pantallas interactivas.</div>
                                <div className='service-tab pb-3'><img src={circulo} className='img-circulo'/>   Scandit.</div>
                                <div className='service-tab pb-3'><img src={circulo} className='img-circulo'/>   Body tracking.</div>
                                <div className='service-tab pb-3'><img src={circulo} className='img-circulo'/>   Over touching.</div>
                                <div className='service-tab pb-3'><img src={circulo} className='img-circulo'/>   Inventory visibility.</div>
                                <div className='service-tab pb-3'><img src={circulo} className='img-circulo'/>   Ventiladores holográficos.</div>
                            </Accordion.Body>
                    </Accordion.Item>   
                    <Accordion.Item eventKey="0">
                        <Accordion.Header ><span className='home-services-title'>Exhibidores</span></Accordion.Header>
                            <Accordion.Body>
                                            <div className='service-tab pb-3'><img src={circulo} className='img-circulo' /> Display multimaterial.</div>
                                            <div className='service-tab pb-3'><img src={circulo} className='img-circulo' /> Glorificadores.</div>
                                            <div className='service-tab pb-3'><img src={circulo} className='img-circulo'/> Vitrinas y escaparates.</div>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="5">
                        <Accordion.Header><span className='home-services-title'>Impresión</span></Accordion.Header>
                            <Accordion.Body>
                                <div className='service-tab pb-3'><img src={circulo} className='img-circulo'/>Figuras en PLA, ABS y otros filamentos.</div>
                                <div className='service-tab pb-3'><img src={circulo} className='img-circulo'/>Impresión digital.</div>
                                <div className='service-tab pb-3'><img src={circulo} className='img-circulo'/>Gran formato.</div>
                                <div className='service-tab pb-3'><img src={circulo} className='img-circulo'/>Cama plana en todo tipo de superficies.</div>
                                <div className='service-tab pb-3'><img src={circulo} className='img-circulo'/>Offset.</div>
                                <div className='service-tab pb-3'><img src={circulo} className='img-circulo'/>Plotter.</div>
                                <div className='service-tab pb-3'><img src={circulo} className='img-circulo'/>Digital.</div>
                                <div className='service-tab pb-3'><img src={circulo} className='img-circulo'/>Láser.</div>
                                <div className='service-tab pb-3'><img src={circulo} className='img-circulo'/>Cortes en router y láser en madera, aglomerados, metales, resinas, vinil y plásticos.</div>
                            </Accordion.Body>
                        </Accordion.Item>
                    
                        <Accordion.Item eventKey="2">
                            <Accordion.Header> <span className='home-services-title'>Interiorismo y decoración</span></Accordion.Header>
                            <Accordion.Body>
                            <div className='service-tab pb-3'><img src={circulo} className='img-circulo'/>Display multimaterial.</div> 
                            <div className='service-tab pb-3'><img src={circulo} className='img-circulo'/>Muebles y elementos para interiorismo.</div>
                            <div className='service-tab pb-3'><img src={circulo} className='img-circulo'/>Celosías en metal.</div>
                        </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="3">
                            <Accordion.Header> <span className='home-services-title'>Moldes</span></Accordion.Header>
                            <Accordion.Body>
                                <div className='service-tab pb-3'><img src={circulo} className='img-circulo'/>Elaboración de moldes en termoformado, silicón,
                                aluminio y aglomerados.</div>
                                <div className='service-tab pb-3'><img src={circulo} className='img-circulo'/>Grabados en router y láser en madera, aglomerados,
                                metales, resinas, plásticos y a color en acero inoxidable.</div>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="6">
                        <Accordion.Header><span className='home-services-title'>Publicidad exterior</span></Accordion.Header>
                            <Accordion.Body>
                                <div className='service-tab pb-3'><img src={circulo} className='img-circulo'/>Soportes publicitarios.</div>
                                <div className='service-tab pb-3'><img src={circulo} className='img-circulo'/>Diseño de modelos 3D.</div>
                                <div className='service-tab pb-3'><img src={circulo} className='img-circulo'/>Rótulos luminosos.</div>
                                <div className='service-tab pb-3'><img src={circulo} className='img-circulo'/>Grandes letreros.</div>
                                <div className='service-tab pb-3'><img src={circulo} className='img-circulo'/>Cajas de luz, plafones y banderolas.</div>
                                <div className='service-tab pb-3'><img src={circulo} className='img-circulo'/>Letras corporeas y recortadas.</div>
                                <div className='service-tab pb-3'><img src={circulo} className='img-circulo'/>Totems publicitarios.</div>
                                <div className='service-tab pb-3'><img src={circulo} className='img-circulo'/>Señalética.</div>
                                <div className='service-tab pb-3'><img src={circulo} className='img-circulo'/>Mobiliario promocional.</div>
                            </Accordion.Body>
                    </Accordion.Item>                   
                        <Accordion.Item eventKey="4">
                        <Accordion.Header><span className='home-services-title'>Volumétricos</span></Accordion.Header>
                        <Accordion.Body>
                        <div className='service-tab pb-3'><img src={circulo} className='img-circulo'/>Diseño de modelos 3D.</div>
                            <div className='service-tab pb-3'><img src={circulo} className='img-circulo'/>Esculpido de volumétricos en unicel, polipropileno, aglomerados, plástico y resina.</div>
                            <div className='service-tab pb-3'><img src={circulo} className='img-circulo'/>Tallado en 3D en madera, aglomerados, resinas y plásticos Impresión en 3D en filamento y resinas</div>
                        </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="1">
                        <Accordion.Header><span className='home-services-title'>Stands</span></Accordion.Header>
                        <Accordion.Body>
                        <div className='service-tab pb-3'><img src={circulo} className='img-circulo'/>Stands</div>
                        <div className='service-tab pb-3'><img src={circulo} className='img-circulo'/> Mesas de experiencia.</div>
                        </Accordion.Body>
                        </Accordion.Item>       
                            </Accordion>
                </Row>
            </Container>
) : (
    <div className='section home-pos' style={{ paddingBottom: '0' }}>
    <Container >
        <Row className='align-items-center'>
            <Col md={ 5 } > <div className='home-title-ment'>Servicios. <span className="line-ment" style={{ borderColor: '#71c2b3' }}></span></div><br/> </Col>
            <Col md={ 7 } > </Col>
        </Row>
        <Row>
            <Col md={ 5 } xs={5} className='service-container'>
                <br/>
                <div className='home-services-title' onClick={() => handleOnClick(5)}>  <img src={mas} className='mas-service' />&nbsp;&nbsp;  Marketing experience</div>
                <div className='home-services-title' onClick={() => handleOnClick(0)}>  <img src={mas} className='mas-service'/>&nbsp;&nbsp; Exhibidores</div>
                <div className='home-services-title' onClick={() => handleOnClick(1)}>  <img src={mas} className='mas-service' />&nbsp;&nbsp;  Impresión</div>
                <div className='home-services-title' onClick={() => handleOnClick(2)}>  <img src={mas} className='mas-service' />&nbsp;&nbsp;  Interiorismo y decoración</div>
                <div className='home-services-title' onClick={() => handleOnClick(3)}>  <img src={mas} className='mas-service' />&nbsp;&nbsp;  Moldes</div>
                <div className='home-services-title' onClick={() => handleOnClick(4)}>  <img src={mas} className='mas-service' />&nbsp;&nbsp;  Publicidad exterior</div>
                <div className='home-services-title' onClick={() => handleOnClick(6)}>  <img src={mas} className='mas-service' />&nbsp;&nbsp;  Volumétricos</div>
                <div className='home-services-title' onClick={() => handleOnClick(7)}>  <img src={mas} className='mas-service'/>&nbsp;&nbsp;  Stands</div>
            </Col>
            <Col md={7} xs={ 7}>
                { serviceIndex == 0 &&
                    <div id="service_0" className={ serviceIndex == 0 ? "animate__animated animate__fadeInUp send-front-90 animate__fast" : "animate__animated animate__slideOutRight send-background" }>
                        <Row>
                            <Col md={1} xs={ 1}>
                            </Col>
                            <Col md={1} xs={ 1}>
                            <img src={servicios} className='img-paloma'/>
                            </Col>
                            <Col md={10} xs={10}>
                                <div className='home-subtitle-white'>Exhibidores<br /></div> <br />
                                <Container className='home-desc-section'>
                                    <Row>
                                        <Col md={ 'auto' }><img src={circulo} className='img-circulo'/> </Col>
                                        <Col><div className='margin-service service-tab'>Display multimaterial.</div></Col>
                                    </Row>
                                    <Row>
                                        <Col md={ 'auto' }><img src={circulo} className='img-circulo'/> </Col>
                                        <Col><div className='margin-service service-tab'>Glorificadores.</div></Col>
                                    </Row>
                                    <Row>
                                        <Col md={ 'auto' }><img src={circulo} className='img-circulo'/> </Col>
                                        <Col><div className='margin-service service-tab'>Vitrinas y escaparates.</div></Col>
                                    </Row>
                                </Container>
                            </Col>
                        </Row>       
                    </div>
                }
                { serviceIndex == 1 &&
                    <div id="service_1" className={ serviceIndex == 1 ? "animate__animated animate__fadeInUp send-front-90 animate__fast" : "animate__animated animate__slideOutRight send-background" }>
                                        <Row>
                                            <Col md={1} xs={1}>
                                            </Col>
                                            <Col md={1} xs={1}>
                                            <img src={servicios} />
                                            </Col>
                                            <Col md={10} xs={10}>
                                                <div className='home-subtitle-white'>Impresión<br /></div> <br />
                                                <Container className='home-desc-section'>
                                                    <Row>
                                                        <Col md={ 'auto' }><img src={circulo} className='img-circulo'/> </Col>
                                                        <Col><div className='margin-service service-tab'>Figuras en PLA, ABS y otros filamentos.</div></Col>
                                                    </Row>
                                                    <Row>
                                                        <Col md={ 'auto' }><img src={circulo} className='img-circulo'/> </Col>
                                                        <Col><div className='margin-service service-tab'>Impresión digital.</div></Col>
                                                    </Row>
                                                    <Row>
                                                        <Col md={ 'auto' }><img src={circulo} className='img-circulo'/> </Col>
                                                        <Col> <samp className='margin-service service-tab'>Gran formato.</samp> </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col md={ 'auto' }><img src={circulo} className='img-circulo'/> </Col>
                                                        <Col> <samp className='margin-service service-tab'>Cama plana en todo tipo de superficies.</samp> </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col md={ 'auto' }><img src={circulo} className='img-circulo'/> </Col>
                                                        <Col> <samp className='margin-service service-tab'>Offset.</samp> </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col md={ 'auto' }><img src={circulo} className='img-circulo'/> </Col>
                                                        <Col> <samp className='margin-service service-tab'>Plotter.</samp> </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col md={ 'auto' }><img src={circulo} className='img-circulo'/> </Col>
                                                        <Col> <samp className='margin-service service-tab'>Digital.</samp> </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col md={ 'auto' }><img src={circulo} className='img-circulo'/> </Col>
                                                        <Col> <samp className='margin-service service-tab'>Láser.</samp> </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col md={ 'auto' }><img src={circulo} className='img-circulo'/> </Col>
                                                        <Col> <samp className='margin-service service-tab'>Cortes en router y láser en madera, aglomerados, metales, resinas, vinil y plásticos.</samp> </Col>
                                                    </Row>
                                                </Container>                                                
                                            </Col>
                                            

                                        </Row>       
                    </div>
                }
                { serviceIndex == 2 &&
                    <div id="service_2" className={ serviceIndex == 2 ? "animate__animated animate__fadeInUp send-front-90 animate__fast" : "animate__animated animate__slideOutRight send-background" }>
                                        <Row>
                                            <Col md={1} xs={1}>
                                            
                                            </Col>
                                            <Col md={1} xs={1}>
                                            <img src={servicios} />
                                            </Col>

                                            <Col md={10} xs={10}>
                                                <div className='home-subtitle-white'>Interiorismo y decoración<br /></div> <br />
                                                <Container className='home-desc-section'>
                                                    <Row>
                                                        <Col md={ 'auto' }><img src={circulo} className='img-circulo'/> </Col>
                                                        <Col><samp className='margin-service service-tab'>Display multimaterial.</samp></Col>
                                                    </Row>
                                                    <Row>
                                                        <Col md={ 'auto' }><img src={circulo} className='img-circulo'/> </Col>
                                                        <Col><samp className='margin-service service-tab'>Muebles y elementos para interiorismo.</samp></Col>
                                                    </Row>
                                                    <Row>
                                                        <Col md={ 'auto' }><img src={circulo} className='img-circulo'/> </Col>
                                                        <Col><samp className='margin-service service-tab'>Celosías en metal.</samp></Col>
                                                    </Row>
                                                </Container>
                                            </Col>
                                        </Row>       
                    </div>
                }
                { serviceIndex == 3 &&
                    <div id="service_3" className={ serviceIndex == 3 ? "animate__animated animate__fadeInUp send-front-90 animate__fast" : "animate__animated animate__slideOutRight send-background" }>
                                        <Row>
                                            <Col md={1}>
                                            
                                            </Col>
                                            <Col md={1}>
                                            <img src={servicios} />
                                            </Col>

                                            <Col md={10} xs={10}>
                                                <div className='home-subtitle-white'>Moldes<br /></div> <br />
                                                <Container className='home-desc-section'>
                                                    <Row>
                                                        <Col md={ 'auto' }><img src={circulo} className='img-circulo'/> </Col>
                                                        <Col><samp className='margin-service service-tab'>Elaboración de moldes en termoformado, silicón, aluminio y aglomerados.</samp></Col>
                                                    </Row>
                                                    <Row>
                                                        <Col md={ 'auto' }><img src={circulo} className='img-circulo'/> </Col>
                                                        <Col><samp className='margin-service service-tab'>Grabados en router y láser en madera, aglomerados, metales, resinas, plásticos y a color en acero inoxidable.</samp></Col>
                                                    </Row>
                                                </Container>
                                            </Col>
                                        </Row>       
                    </div>
                    }
                    { serviceIndex == 4 &&
                    <div id="service_4" className={ serviceIndex == 4 ? "animate__animated animate__fadeInUp send-front-90 animate__fast" : "animate__animated animate__slideOutRight send-background" }>
                                        <Row>
                                            <Col md={1}>
                                            
                                            </Col>
                                            <Col md={1}>
                                            <img src={servicios} />
                                            </Col>

                                            <Col md={10} xs={10}>
                                                <div className='home-subtitle-white'>Publicidad exterior<br /></div> <br />
                                                <Container className='home-desc-section'>
                                                    <Row>
                                                        <Col md={ 'auto' }><img src={circulo} className='img-circulo'/> </Col>
                                                        <Col> <samp className='margin-service service-tab'>Soportes publicitarios.</samp></Col>
                                                    </Row>
                                                    <Row>
                                                        <Col md={ 'auto' }><img src={circulo} className='img-circulo'/> </Col>
                                                        <Col><samp className='margin-service service-tab'>Diseño de modelos 3D.</samp></Col>
                                                    </Row>
                                                    <Row>
                                                        <Col md={ 'auto' }><img src={circulo} className='img-circulo'/> </Col>
                                                        <Col><samp className='margin-service service-tab'>Rótulos luminosos.</samp></Col>
                                                    </Row>
                                                    <Row>
                                                        <Col md={ 'auto' }><img src={circulo} className='img-circulo'/> </Col>
                                                        <Col> <samp className='margin-service service-tab'>Grandes letreros.</samp> </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col md={ 'auto' }><img src={circulo} className='img-circulo'/> </Col>
                                                        <Col>  <samp className='margin-service service-tab'>Cajas de luz, plafones y banderolas.</samp> </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col md={ 'auto' }><img src={circulo} className='img-circulo'/> </Col>
                                                        <Col>  <samp className='margin-service service-tab'>Letras corporeas y recortadas.</samp> </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col md={ 'auto' }><img src={circulo} className='img-circulo'/> </Col>
                                                        <Col>  <samp className='margin-service service-tab'>Totems publicitarios.</samp> </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col md={ 'auto' }><img src={circulo} className='img-circulo'/> </Col>
                                                        <Col> <samp className='margin-service service-tab'>Señalética.</samp> </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col md={ 'auto' }><img src={circulo} className='img-circulo'/> </Col>
                                                        <Col> <samp className='margin-service service-tab'>Mobiliario promocional.</samp> </Col>
                                                    </Row>
                                                </Container>                                                
                                            </Col>                                            
                                        </Row>       
                    </div>
                }
                { serviceIndex == 5 &&
                    <div id="service_5" className={ serviceIndex == 5 ? "animate__animated animate__fadeInUp send-front-90 animate__fast" : "animate__animated animate__slideOutRight send-background" }>
                                        <Row>
                                            <Col md={1}>
                                            
                                            </Col>
                                            <Col md={1}>
                                            <img src={servicios} />
                                            </Col>

                                            <Col md={10} xs={10}>
                                                <div className='home-subtitle-white'>Marketing experiencie<br /></div> <br />
                                                <Container className='home-desc-section'>
                                                    <Row>
                                                        <Col md={ 'auto' }><img src={circulo} className='img-circulo'/> </Col>
                                                        <Col> <samp className='margin-service service-tab'>Cenefas digitales.</samp></Col>
                                                    </Row>
                                                    <Row>
                                                        <Col md={ 'auto' }><img src={circulo} className='img-circulo'/> </Col>
                                                        <Col><samp className='margin-service service-tab'>Preciadores digitales.</samp></Col>
                                                    </Row>
                                                    <Row>
                                                        <Col md={ 'auto' }><img src={circulo} className='img-circulo'/> </Col>
                                                        <Col><samp className='margin-service service-tab'>Wi - spot.</samp></Col>
                                                    </Row>
                                                    <Row>
                                                        <Col md={ 'auto' }><img src={circulo} className='img-circulo'/> </Col>
                                                        <Col> <samp className='margin-service service-tab'>Video box.</samp> </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col md={ 'auto' }><img src={circulo} className='img-circulo'/> </Col>
                                                        <Col>  <samp className='margin-service service-tab'>Vending machine.</samp> </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col md={ 'auto' }><img src={circulo} className='img-circulo'/> </Col>
                                                        <Col>  <samp className='margin-service service-tab'>Retail analytics.</samp> </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col md={ 'auto' }><img src={circulo} className='img-circulo'/> </Col>
                                                        <Col>  <samp className='margin-service service-tab'>Self checkout.</samp> </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col md={ 'auto' }><img src={circulo} className='img-circulo'/> </Col>
                                                        <Col> <samp className='margin-service service-tab'>Pantallas interactivas.</samp> </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col md={ 'auto' }><img src={circulo} className='img-circulo'/> </Col>
                                                        <Col> <samp className='margin-service service-tab'>Scandit.</samp> </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col md={ 'auto' }><img src={circulo} className='img-circulo'/> </Col>
                                                        <Col> <samp className='margin-service service-tab'>Body tracking.</samp> </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col md={ 'auto' }><img src={circulo} className='img-circulo'/> </Col>
                                                        <Col> <samp className='margin-service service-tab'>Over touching.</samp> </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col md={ 'auto' }><img src={circulo} className='img-circulo'/> </Col>
                                                        <Col> <samp className='margin-service service-tab'>Inventory visibility.</samp> </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col md={ 'auto' }><img src={circulo} className='img-circulo'/> </Col>
                                                        <Col> <samp className='margin-service service-tab'>Ventiladores holográficos.</samp> </Col>
                                                    </Row>
                                                </Container>                                                
                                            </Col>
                                        </Row>       
                    </div>
                }
                { serviceIndex == 6 &&
                    <div id="service_6" className={ serviceIndex == 6 ? "animate__animated animate__fadeInUp send-front-90 animate__fast" : "animate__animated animate__slideOutRight send-background" }>
                                        <Row>
                                            <Col md={1}>
                                            
                                            </Col>
                                            <Col md={1}>
                                            <img src={servicios} />
                                            </Col>
                                            <Col md={10} xs={10}>
                                                <div className='home-subtitle-white'>Volumétricos<br /></div> <br />
                                                <Container className='home-desc-section'>
                                                    <Row>
                                                        <Col md={ 'auto' }><img src={circulo} className='img-circulo'/> </Col>
                                                        <Col><samp className='margin-service service-tab'>Diseño de modelos 3D.</samp></Col>
                                                    </Row>
                                                    <Row>
                                                        <Col md={ 'auto' }><img src={circulo} className='img-circulo'/> </Col>
                                                        <Col><samp className='margin-service service-tab'>Esculpido de volumétricos en unicel, polipropileno, aglomerados, plástico y resina.</samp></Col>
                                                    </Row>
                                                    <Row>
                                                        <Col md={ 'auto' }><img src={circulo} className='img-circulo'/> </Col>
                                                        <Col><samp className='margin-service service-tab'>Tallado en 3D en madera, aglomerados, resinas y plásticos.</samp></Col>
                                                    </Row>
                                                    <Row>
                                                        <Col md={ 'auto' }><img src={circulo} className='img-circulo'/> </Col>
                                                        <Col><samp className='margin-service service-tab'>Impresión en 3D en filamento y resinas.</samp></Col>
                                                    </Row>
                                                </Container>
                                            </Col>
                                        </Row>       
                    </div>
                    }             
                     { serviceIndex == 7 &&
                    <div id="service_7" className={ serviceIndex == 7 ? "animate__animated animate__fadeInUp send-front-90 animate__fast" : "animate__animated animate__slideOutRight send-background" }>
                                        <Row>
                                            <Col md={1}>
                                            
                                            </Col>
                                            <Col md={1}>
                                            <img src={servicios} />
                                            </Col>

                                            <Col md={10} xs={10}>
                                                <div className='home-subtitle-white'>Stands<br /></div> <br />
                                                <Container className='home-desc-section'>
                                                    <Row>
                                                        <Col md={ 'auto' }><img src={circulo} className='img-circulo'/> </Col>
                                                        <Col><samp className='margin-service service-tab'>Stands.</samp></Col>
                                                    </Row>
                                                    <Row>
                                                        <Col md={ 'auto' }><img src={circulo} className='img-circulo'/> </Col>
                                                        <Col><samp className='margin-service service-tab'>Mesas de experiencia.</samp></Col>
                                                    </Row>
                                                </Container>
                                            </Col>
                                        </Row>       
                    </div>
                    }                        
               
            </Col>
        </Row>
    </Container>
    <br/>
    </div>
)}
              
                   
        
                            
                <div>
                    <Container className="full-container">
                        </Container>
              
                </div>
                        
                    
                </div>
                
                <div ref={refBrands} className='section'>
                    <Container>
                        <Row>
                            <div className='home-title-black'>
                                Marcas. <span className="line"></span>
                            </div>
                        </Row>
                        <br/><br/>
                        <Row>
                            <div>
                                <Marquee direction="left">
                                    <img src={cashi} className='marquee-img' />
                                    <img src={bic} className='marquee-img' />
                                    <img src={sams} className='marquee-img' />
                                    <img src={walmart} className='marquee-img'/>
                                </Marquee>
                            </div>
                            <div style={{ height: '50px' }}></div>
                            <div  style={{speed:6,direction:"right"}}>
                                
                            <Marquee direction="right">
                            <img src={mercado} className='marquee-img' />
                                <img src={bandera} className='marquee-img'/>
                                <img src={nestle} className='marquee-img'/>
                                <img src={speedo} className='marquee-img'/>
                                <img src={nature} className='marquee-img'/>
                            </Marquee>
                            </div>
                        </Row>
                        <br/><br/>
                    </Container>
                </div>
        </div>
    </>
    );
}
export default Home;