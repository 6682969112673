import '../App.css';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { Container } from 'react-bootstrap';
import { isMobile } from 'react-device-detect';

import free1 from '../img/free1.png'
import free2 from '../img/free2.png'
import free3 from '../img/free3.png'
import free4 from '../img/free4.png'
import free5 from '../img/free5.png'
import free6 from '../img/free6.png'
import free7 from '../img/free7.png'


function Free() {
    return (<>
        <div>
        <Container>
        <div style={{ height:  isMobile? '15px':'30px' }}></div>
        <div className='home-title-black'>FREE FIRE</div>
                <div style={{ height: isMobile? '15px':'30px' }}></div>
            </Container>
        <Container>
        <Row>
                        <Col sm={4} xl={8} xs={12}>
                            <Row className="align-items-center containers-photos">
                                <Col className='containers-photos containers-photos-left'>
                                    <img src={free1} className='imgs-containers' />
                                </Col>
                                <Col className='containers-photos containers-photos-right text-desc-book'>
                                        <div className='text-title-book'>INTERIORISMO Y
                                                DECORACIÓN. <br/>
                                            <div className='text-desc-book'>Free Fire es un juego Battle Royale
                                            multijugador para móviles, desarrollado por
                                            111dots Studi y publicado por Garena para
                                            Android e iOS.</div></div>
                                </Col>
                            </Row>
                            <Row>
                                <Col><img src={free2} className='imgs-containers photos-stants-right' /></Col>
                                <Col><img src={free3} className='imgs-containers photos-stants' /></Col>
                            </Row>
                    </Col>
                    
                        <Col sm={4} xl={4} xs={12}>
                    <Row>    <img src={free4} className='imgs-containers margin-zero' /></Row>
                        <Row>
                        <img src={free5} className='imgs-containers img-separation-top' /></Row>
                    </Col> 
                    <Row>
                        <Col sm={1} xl={4} xs={12}>
                        <img src={free6} className='imgs-containers img-separation-top' />
                        </Col>
                        <Col sm={3} xl={8} xs={12}>
                        <img src={free7} className='imgs-containers img-separation-top' />
                        </Col>
                    </Row>
                    
  </Row>


  <Row>
                <Col md={10} xs={9}>
                </Col>
                <Col md={2} xs={3} className='home-title-book'>
                <div><a href="/?s=refBook">Book.</a></div>
                </Col>
            </Row>
        </Container>
        <div style={{ height:  isMobile? '15px':'86px' }}></div>
    </div>
    
    </>)
}
export default Free;