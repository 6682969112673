import '../App.css';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { Container } from 'react-bootstrap';
import { isMobile } from 'react-device-detect';

import ramon1 from '../img/ramon/ramon1.png'
import ramon2 from '../img/ramon/ramon2.png'
import ramon3 from '../img/ramon/ramon3.png'
import ramon4 from '../img/ramon/ramon4.png'

function Ramon() {
    return (<>
        <div>
        <Container>
        <div style={{ height: isMobile? '15px':'30px' }}></div>
        <div className='home-title-black'>DON RAMÓN</div>
        <div style={{ height: isMobile? '15px':'30px' }}></div>
        <Container>
                <Row>
                    <Col sm={4} xl={8} xs={12}>
                        <Row>
                            <Col>
                                <Container>
                                    <Row className="align-items-center containers-photos">
                                        <Col className='containers-photos containers-photos-left'>
                                            <img src={ramon1} className='imgs-containers' />
                                        </Col>
                                        <Col className='containers-photos containers-photos-right text-desc-book'>
                                            <div className='text-title-book'>GLORIFICADORES y EXHIBIDOS.<br/>
                                            <div className='text-desc-book'>Los glorificadores se colocaron en puntos de alta afluencia de N.S.E Alto, debido al valor de estos tequilas. La intención fue resolver un tema de visibilidad para productos de alta gama.</div></div>
                                        </Col>
                                    </Row>
                                </Container>
                            </Col>
                        </Row>
                        <Row>
                            <Col className='colums-padding'><img src={ramon2} className='imgs-containers photos-stants-right' /></Col>
                            <Col className='colums-padding'><img src={ramon3 } className='imgs-containers photos-stants' /></Col>
                        </Row>
                    </Col>
                    <Col sm={4} xl={4} xs={12}>
                        <img src={ramon4} className='imgs-containers' />
                    </Col> 
                </Row>
            </Container>
            <Row>
                <Col md={10}xs={9}>
                </Col>
                <Col md={2} xs={3} className='home-title-book'>
                <div><a href="/?s=refBook">Book.</a></div>
                </Col>
            </Row>
            </Container>
        <div style={{ height: isMobile? '15px':'86px' }}></div>
    </div>
    
    </>)
}
export default Ramon;