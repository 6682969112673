import '../App.css';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { Container } from 'react-bootstrap';
import { isMobile } from 'react-device-detect';

import tequila1 from '../img/tequila1.png'
import tequila2 from '../img/tequila2.png'
import tequila3 from '../img/tequila3.png'
import tequila4 from '../img/tequila4.png'
import tequila5 from '../img/tequila5.png'
import tequila6 from '../img/tequila6.png'


function Tequila() {
    return (<>
        <div>
            <Container>
            <div style={{ height: isMobile? '15px': '30px' }}></div>
            <div className='home-title-black'>TEQUILA PRIMO</div>
            <div style={{ height: isMobile? '15px':'30px' }}></div>
            </Container>
                <Container>
                    <Row>
                            <Col sm={4} xl={8} xs={12}>
                                <Row className="align-items-center containers-photos">
                                    <Col className='containers-photos containers-photos-left'>
                                        <img src={tequila1} className='imgs-containers' />
                                    </Col>
                                    <Col className='containers-photos containers-photos-right text-desc-book'>
                                            <div className='text-title-book'>MOLDES (GLORIFICADOR). <br/>
                                                <div className='text-desc-book'>Más de 200 años respaldan la historia y calidad de este tequila que hace honor a su legado. Se destaca por ser impulsor de la industria tequilera en Jalisco y la primera en sembrar Agave azul en la región de Arandas.</div></div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col><img src={tequila2} className='imgs-containers photos-stants-right' /></Col>
                                    <Col><img src={tequila3 } className='imgs-containers photos-stants ' /></Col>
                                </Row>
                            </Col>
                            <Col sm={4} xl={4} xs={12}>
                                <img src={tequila4} className='imgs-containers margin-zero' />
                        </Col> 
                        <Row>
                            <Col sm={3} xl={8}xs={12}>
                                <img src={tequila5} className='imgs-containers img-separation-top' />
                            </Col>
                            <Col sm={1} xl={4}xs={12}>
                                <img src={tequila6} className='imgs-containers img-separation-top' />
                            </Col>
                        </Row>
                </Row>
                <Row>
                <Col md={10}xs={9}>
                </Col>
                <Col md={2} xs={3} className='home-title-book'>
                <div><a href="/?s=refBook">Book.</a></div>
                </Col>
            </Row>
            </Container>        
       
        <div style={{ height: isMobile? '15px':'86px' }}></div>
    </div>
    
    </>)
}
export default Tequila;