import '../App.css';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { Container } from 'react-bootstrap';
import { isMobile } from 'react-device-detect';
import PhotoAlbum from "react-photo-album";

import six1 from '../img/six/six1.png'
import six2 from '../img/six/six2.png'

const HOST = 'https://studio36mx.com/'
//const HOST = 'http://localhost:3000/'

const photos = [
    {
        src: HOST + 'six/six3.png',
        width: 525,
        height: 562
    },
    {
        src: HOST + 'six/six4.png',
        width: 520,
        height: 430
    },
    {
        src: HOST + 'six/six5.png',
        width: 525,
        height: 304
    },
    {
        src: HOST + 'six/six6.png',
        width: 489,
        height: 495
    },
    {
        src: HOST + 'six/six7.png',
        width: 1100,
        height: 495
    },
    {
        src: HOST + 'six/six8.png',
        width: 1013,
        height: 585
    },
    {
        src: HOST + 'six/six9.png',
        width: 578,
        height: 585
    },
    {
        src: HOST + 'six/six10.png',
        width: 487,
        height: 497
    },
    {
        src: HOST + 'six/six11.png',
        width: 1101,
        height: 497
    },
    {
        src: HOST + 'six/six12.png',
        width: 619,
        height: 495
    },
    {
        src: HOST + 'six/six13.png',
        width: 958,
        height: 495
    },
    {
        src: HOST + 'six/six14.png',
        width: 1013,
        height: 585
    },
    {
        src: HOST + 'six/six15.png',
        width: 578,
        height: 585
    }
    
];

function Arte() {
    return (<>
        <div>
        <Container>
        <div style={{ height: isMobile? '15px':'30px' }}></div>
        <div className='home-title-black'>SIX FLAGS</div>
        <div style={{ height: isMobile? '15px':'30px' }}></div>
        <Container>
                <Row className='align-items-center'>
                    <Col sm={4} xl={8} xs={12}>
                        <Row>
                            <Col>
                                <Container>
                                    <Row className="align-items-center containers-photos">
                                        <Col className='containers-photos containers-photos-left'>
                                            <img src={six1} className='imgs-containers' />
                                        </Col>
                                        <Col className='containers-photos containers-photos-right text-desc-book'>
                                                <div className='text-title-book'> <span style={{ textTransform: 'uppercase' }}>Interiorismo y decoración y publicidad exterior.</span><br/>
                                                <div className='text-desc-book'>Somos un aliado estratégico importante para Six Flags México en el desarrollo de carros alegóricos, volumétricos de hasta 3 metros de alto y photo opportunity.</div></div>
                                        </Col>
                                    </Row>
                                </Container>
                            </Col>
                        </Row>
                    </Col>
                    <Col sm={4} xl={4} xs={12}>
                        <img src={six2} className='imgs-containers' />
                    </Col> 
                </Row>
            </Container>
            <br/>
            <Row>
                <Col>
                    <PhotoAlbum layout={ isMobile ? 'columns' : 'rows' } photos={photos} columns={ isMobile ? 1 : 0 }/>
                </Col>
            </Row>
            <br/>
            <Row>
                <Col md={10}xs={9}>
                </Col>
                <Col md={2} xs={3} className='home-title-book'>
                <div><a href="/?s=refBook">Book.</a></div>
                </Col>
            </Row>
        </Container>
        <div style={{ height: isMobile? '15px':'86px' }}></div>
    </div>
    
    </>)
}
export default Arte;